import { App } from './App';  // Используем фигурные скобки для именованного импорта

document.addEventListener('DOMContentLoaded', async function () {
    const notesContainer = document.querySelector('.notes__main-container');
    const mainNote = document.querySelector('.notes__main-note'); 
    const secondaryContainer = document.querySelector('.notes__secondary-container');
    // const rect = document.querySelector('.rect-right');
    const cloudsSection = document.querySelector('.clouds-type');
    const chatContainer = document.querySelector('.chat-container');
    const cameraSection = document.querySelector('.camera');
    const footerBar = document.querySelector('.footer-bar');
    const homeLabel = document.querySelector('.home-button__label');
    const chatLabel = document.querySelector('.chat-button__label');
    const cloudsLabel = document.querySelector('.clouds-button__label');
    const notesContentContainer = document.querySelector('.content-container');
    const videoElement = document.getElementById('preview');
    const fileInput = document.getElementById('file-input');
    let selectedBtn;
    const noteImage = document.getElementById('note-image');
    const cloudTitle = document.getElementById('cloud-title');
    const cloudDescription = document.getElementById('cloud-description');
    const takePicBtn = document.getElementById('takePicture');
    const closeBtn = document.querySelector('.button-close-response');
    //   const searchForm = document.querySelector('.search-field');
     const openLib = document.getElementById('openLibrary');
     const cloudsButton = document.getElementById('clouds-button');
    const homeButton = document.getElementById('home-button');
    const chatButton = document.getElementById('chat-button');
  

    let cameraStream = null;
    const searchForm = document.querySelector('.search-field');
    searchForm.addEventListener('submit', function(e) {
        e.preventDefault(); // Предотвращаем обновление страницы
        sendMessageToServer(); // Отправка сообщения
    });

function closeCamera() {
   if (cameraStream) {
       cameraStream.getTracks().forEach(track => track.stop());
       cameraStream = null;
   }
   videoElement.srcObject = null;
}
window.addEventListener('beforeunload', closeCamera);
    footerBar.addEventListener('click', (event) => {
        const elem = event.target.closest('label');
        if (!elem || !footerBar.contains(elem)) return;

        highlightButton(elem);

        if (homeLabel.classList.contains('highlight')) {
            notesContainer.classList.remove('hidden');
            showSection(notesContainer);
            closeCamera()
        } else if (chatLabel.classList.contains('highlight')) {
            notesContentContainer.classList.add('hidden');
            showSection(chatContainer);
            closeCamera();
        } else if (cloudsLabel.classList.contains('highlight')) {
            openCamera();
            notesContentContainer.classList.add('hidden');
            showSection(cameraSection);
        }
    });
      // Переход на раздел "Облака" (включаем камеру)
    // cloudsButton.addEventListener('click', () => {
        
    //     openCamera();
    // });

    // // Переход на домашний раздел (выключаем камеру)
    // homeButton.addEventListener('click', () => {
       
    //     closeCamera(); // Останавливаем камеру
    // });

    // // Переход на чат (выключаем камеру)
    // chatButton.addEventListener('click', () => {
     
    //     closeCamera(); // Останавливаем камеру
    // });

    // window.addEventListener('beforeunload', closeCamera);
    function showSection(sectionToShow) {
        [notesContainer, chatContainer, cameraSection].forEach((section) => {
            if (section === sectionToShow) {
                section.classList.remove('hidden');
            } else {
                section.classList.add('hidden');
            }
        });
    }

    if (mainNote && secondaryContainer && rect) {
        mainNote.addEventListener('click', () => {
            const isHidden = secondaryContainer.classList.toggle('hidden');
            rect.classList.toggle('rotate', !isHidden);
        });
    }

    async function loadNotes() {
        try {
            const response = await fetch('http://localhost:8000/api/notes');
            const notesTree = await response.json();
    
            if (notesTree && typeof notesTree === 'object') {
                Object.entries(notesTree).forEach(([key, value]) => {
                    const cloudNote = document.createElement('li');
                    cloudNote.classList.add('notes__main-note');
                    cloudNote.textContent = key;
    
                    const rect = document.createElement('img');
                    rect.src = '../images/RectangleRight.svg';
                    rect.classList.add('rect-right');
    
                    // Добавляем в основной контейнер
                    notesContainer.appendChild(cloudNote);
                    cloudNote.appendChild(rect);
    
                    const cloudList = document.createElement('ul');
                    cloudList.classList.add('notes__secondary-container', 'hidden');
    
                    // Вставляем ul после li
                    notesContainer.insertBefore(cloudList, cloudNote.nextSibling);
    
                    cloudNote.addEventListener('click', () => {
                        cloudList.classList.toggle('hidden');
                        rect.classList.toggle('rotate');
                    });
    
                    Object.keys(value).forEach((fileName) => {
                        const filePath = `http://localhost:8000/api/notes/${key}/${fileName}`;
                        const cloudItem = document.createElement('li');
                        cloudItem.classList.add('notes__secondary-note');
                        cloudItem.textContent = fileName.replace('.md', '');
    
                        cloudItem.addEventListener('click', () => {
                            loadNoteContent(filePath);
                        });
    
                        cloudList.appendChild(cloudItem);
                    });
                });
            } else {
                console.error('Данные отсутствуют или некорректны:', notesTree);
            }
        } catch (error) {
            console.error('Ошибка загрузки списка заметок:', error);
        }
    }
   
    
    async function loadNoteContent(filePath) {
        try {
            if (!filePath) {
                throw new Error('Недопустимый путь к файлу.');
            }
    
            console.log(`Загружаем контент из: ${filePath}`);
            const response = await fetch(filePath);
            const content = await response.text();
            displayMarkdown(content); // Отображаем Markdown-контент
        } catch (error) {
            console.error('Ошибка загрузки контента:', error);
        }
    }
   

    function displayMarkdown(content) {
        const contentContainer = document.querySelector('.content-container');
        if (!contentContainer) {
            console.error('Контейнер для контента не найден.');
            return;
        }
    
        // Очистка контейнера перед добавлением контента
        contentContainer.innerHTML = '';
    
        // Добавляем HTML-разметку заметки
        const markdownContent = document.createElement('div');
        markdownContent.innerHTML = marked.parse(content);
    
        // Создание кнопки закрытия
        const closeButton = document.createElement('button');
        
        closeButton.classList.add('close-button');
    
        // Обработчик кнопки закрытия
        closeButton.addEventListener('click', () => {
            contentContainer.classList.add('hidden');
            contentContainer.innerHTML = ''; // Очистка контента
            document.querySelector('.notes__main-container').classList.remove('hidden'); // Возврат к списку
        });
    
        // Добавляем контент и кнопку в контейнер
        contentContainer.appendChild(markdownContent);
        contentContainer.appendChild(closeButton);
        
        // Показываем контейнер
        contentContainer.classList.remove('hidden');
        
        document.querySelector('.notes__main-container').classList.add('hidden'); // Скрываем список
        if(notesContentContainer.classList.contains('hidden')){
            document.querySelector('.close-button').classList.add('hidden')
        }
    }
    
  

    // Обработка нажатий на кнопки в футере
    footerBar.addEventListener('click', (event) => {
        const elem = event.target.closest('label');
        if (!elem || !footerBar.contains(elem)) return;
        highlightButton(elem);

        if (elem.classList.contains('home-button__label')) {
            notesContainer.classList.remove('hidden');
            
        }
    });

    // Подсветка выбранной кнопки
    function highlightButton(elem) {
        if (selectedBtn) {
            selectedBtn.classList.remove('highlight');
        }
        selectedBtn = elem;
        selectedBtn.classList.add('highlight');
    }

    // Загрузка начальных данных
    loadNotes();
    const inputField = document.getElementById('search-field__input');
    const inputLabel = document.querySelector('.search-field__input-label')
 
     // Поднимаем поле ввода при открытии клавиатуры
     inputField.addEventListener('focus', () => {
         inputLabel.classList.add('search-field__input-focused');
     });
 
     // Сбрасываем поднятие при потере фокуса
     inputField.addEventListener('blur', () => {
         inputLabel.classList.remove('search-field__input-focused');
     });
 
     // Закрываем клавиатуру при клике вне поля ввода
     document.addEventListener('click', (event) => {
         if (!inputField.contains(event.target) && event.target !== inputField) {
             inputField.blur(); // Скрытие клавиатуры
         }
     });
    
     // const chatContainer = document.querySelector('.chat-container');
     // const cameraSection = document.querySelector('.camera');
    
  openLib.addEventListener('click', () => {
     // const headerBar = document.querySelector('.header-bar');
     // headerBar.classList.add('hidden');
     const mesIn = document.querySelectorAll('.incoming');
     const mesOut = document.querySelectorAll('.outgoing');
     
     
    mesIn.forEach(mes => mes.classList.add('hidden'));
    mesOut.forEach(mes => mes.classList.add('hidden'));
     closeBtn.classList.remove('hidden');
    
     chatContainer.classList.add('active-chat');
   
     searchForm.classList.add('hidden');
 });
 
 takePicBtn.addEventListener('click', () => {
     // const headerBar = document.querySelector('.header-bar');
     // headerBar.classList.add('hidden');
    
     closeBtn.classList.remove('hidden');
     const mesIn = document.querySelectorAll('.incoming');
     const mesOut = document.querySelectorAll('.outgoing');
    
    mesIn.forEach(mes => mes.classList.add('hidden'));
    mesOut.forEach(mes => mes.classList.add('hidden'));
     chatContainer.classList.add('active-chat');
   
     searchForm.classList.add('hidden');
 });
 closeBtn.addEventListener('click', ()=>{
     const mesIn = document.querySelectorAll('.incoming');
     const mesOut = document.querySelectorAll('.outgoing');
     mesIn.forEach(mes => mes.classList.remove('hidden'));
     mesOut.forEach(mes => mes.classList.remove('hidden'));
     closeBtn.classList.add('hidden');
     chatContainer.classList.remove('active-chat');
     searchForm.classList.remove('hidden');
     const userImg = document.querySelector('.user-image');
     userImg.remove();
     const cloudTitles = document.querySelectorAll('.cloud-title');
     const cloudDescriptions = document.querySelectorAll('.cloud-description');
     cloudTitles.forEach(title => title.remove());
     cloudDescriptions.forEach(description => description.remove());
 })
     const header = document.querySelector('.header');
     const cameraContainer = document.querySelector('.camera-container');
     const constraints = {
         video: true,
         audio: false
     };
 
     async function openCamera() {
         try {
            cameraStream = await navigator.mediaDevices.getUserMedia(constraints);
            videoElement.srcObject = cameraStream;
 
             document.getElementById('reverseCamera').addEventListener('click', () => {
                 constraints.video = constraints.video ? { width: { ideal: 1280 } } : { width: { ideal: 640 } };
                 openCamera();
             });
 
             document.getElementById('openLibrary').addEventListener('click', () => fileInput.click());
             document.getElementById('takePicture').addEventListener('click', captureAndSendImage);
         } catch (error) {
             console.error('Error accessing camera:', error);
             alert('Не удалось получить доступ к камере. Проверьте разрешения.');
         }
     }
 
     fileInput.addEventListener('change', async (event) => {
         if (event.target.files && event.target.files.length > 0) {
             const file = event.target.files[0];
             const reader = new FileReader();
             reader.onload = (e) => processAndSendImage(e.target.result);
             reader.readAsDataURL(file);
         }
     });
 
     function captureAndSendImage() {
         const canvas = document.createElement('canvas');
         canvas.width = videoElement.videoWidth;
         canvas.height = videoElement.videoHeight;
         const ctx = canvas.getContext('2d');
         ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
 
         const dataUrl = canvas.toDataURL('image/jpeg');
         processAndSendImage(dataUrl);
     }
 
     async function processAndSendImage(dataUrl) {
         const imgElement = document.createElement('img');
         imgElement.src = dataUrl;
         imgElement.classList.add('user-image');
         
         addImageToChat(imgElement); // Добавляем изображение в чат
 
         const base64Data = dataUrl.split(',')[1];
         const blob = base64ToBlob(base64Data, 'image/jpeg');
 
         const formData = new FormData();
         formData.append('file', blob, 'image.jpg');
 
         try {
             const res = await fetch('http://localhost:8000/process_image', { method: 'POST', body: formData });
 
             if (!res.ok) throw new Error(`Ошибка: ${res.status} - ${await res.text()}`);
 
             const responseData = await res.json();
             displayResponseInChat(responseData);
         } catch (error) {
             console.error('Ошибка при отправке изображения:', error);
         }
     }
 
     function addImageToChat(imgElement) {
         const imgWrapper = document.createElement('div');
         imgWrapper.classList.add('chat-message', 'outgoing');
         imgWrapper.appendChild(imgElement);
         chatContainer.appendChild(imgWrapper);
     }
 
     function displayResponseInChat(responseData) {
         const textWrapper = document.createElement('div');
         textWrapper.classList.add('chat-message', 'incoming');
 
         const cloudTitle = document.createElement('h3');
         cloudTitle.textContent = `Cloud Type: ${responseData.cloud_type}`;
         cloudTitle.classList.add('cloud-title');
 
         const cloudDescription = document.createElement('p');
         cloudDescription.textContent = responseData.description;
         cloudDescription.classList.add('cloud-description');
 
     
 
         textWrapper.appendChild(cloudTitle);
         textWrapper.appendChild(cloudDescription);
        
         chatContainer.appendChild(textWrapper);
 
         chatContainer.scrollTop = chatContainer.scrollHeight;
     }
 
 
     function base64ToBlob(base64Data, contentType = 'image/jpeg') {
     const byteCharacters = atob(base64Data);
     const byteArrays = [];
 
     for (let i = 0; i < byteCharacters.length; i += 512) {
         const slice = byteCharacters.slice(i, i + 512);
         const byteNumbers = new Array(slice.length);
 
         for (let j = 0; j < slice.length; j++) {
             byteNumbers[j] = slice.charCodeAt(j);
         }
 
         byteArrays.push(new Uint8Array(byteNumbers));
     }
 
     return new Blob(byteArrays, { type: contentType });
 }
 async function sendMessageToServer() {
     const inputField = document.getElementById('search-field__input');
     const message = inputField.value.trim();
 
     if (!message) return;
 
     // Добавляем сообщение пользователя в чат
     addMessageToChat(message, 'outgoing');
 
     try {
         // Отправка сообщения на сервер
         const res = await fetch('http://localhost:8000/handle_message', {  
             method: 'POST',
             headers: {
                 'Content-Type': "application/json", 
             },
             body: JSON.stringify({ content: message }) 
         });
         
         const responseData = await res.json();
 console.log(responseData);
         // Добавляем ответ сервера в чат
         addMessageToChat(responseData.reply, 'incoming');
     } catch (error) {
         console.error('Ошибка при отправке сообщения:', error);
         addMessageToChat('Ошибка на сервере', 'incoming');
     }
 
     inputField.value = '';
 }
 
 function addMessageToChat(text, type) {
     const chatContainer = document.querySelector('.chat-container');
     const message = document.createElement('div');
     message.classList.add('message', type);
     message.textContent = text;
     chatContainer.appendChild(message);
     chatContainer.scrollTop = chatContainer.scrollHeight;
 }
 
 // Отправка сообщения по клику или по Enter
 document.querySelector('.search-field__button').addEventListener('click', sendMessageToServer);
 document.getElementById('search-field__input').addEventListener('keypress', (e) => {
     if (e.key === 'Enter') {
         e.preventDefault();
         sendMessageToServer();
     }
 });
 });
 
